<form [formGroup]="formGroup" class="bg-gray-50 p-2 rounded-2.5xl" #formElm>
  <app-search-type-nav
    [showTypes]="showTypes"
    [noInit]="noInit()"
  ></app-search-type-nav>

  <div class="bg-primary p-1 rounded">
    <input
      type="text"
      class="p-2 rounded w-full border-0 bg-gray-50"
      formControlName="input"
      [placeholder]="
        '請輸入物件名稱、社區名稱、街道、編號，則一輸入' | translate
      "
    />
  </div>

  @if (classification.value !== undefined) {
    <app-rent-house
      [cities$]="cities$"
      [expanded]="expanded()"
      [onlyProperty]="true"
    ></app-rent-house>
  }

  <!--  -->

  @if (queries$ | async; as queries) {
    @if (queries.length > 0) {
      <div class="flex flex-wrap mt-4">
        <ul class="flex flex-wrap gap-1">
          <li>
            <b class="text-secondary">
              <mat-icon>arrow_right</mat-icon>
            </b>
          </li>
          @for (query of queries; track query; let i = $index) {
            <li
              (click)="query.delete()"
              class="flex items-center gap-1 cursor-pointer border border-gray-30 px-2 rounded bg-gray-400 text-white"
            >
              <span>
                {{ query.type | translate }} -
                {{ query.value.name | translate }}
              </span>
              <mat-icon>close</mat-icon>
            </li>
          }
        </ul>
      </div>
    }
  }
  <div class="flex flex-col md:flex-row items-center">
    @if (searchResult()) {
      <div>
        <span class="text-secondary">
          共 {{ searchResult().totalCount }} 筆
          @if (searchResult().pageInfo.hasNextPage) {
            <a
              class="underline text-accent cursor-pointer"
              (click)="search(true)"
              >{{ '查看更多' | translate }}
            </a>
          }
        </span>
      </div>
    }

    <ng-content select="[search-action]"></ng-content>

    <i class="flex-auto"></i>
    <div class="flex gap-2 mt-2">
      @if (isClearAll) {
        <button
          type="button"
          mat-button
          color="accent"
          (click)="clear()"
          class="text-xs mr-2"
        >
          <mat-icon>delete_forever</mat-icon>
          {{ '清除全部' | translate }}
        </button>
      }
      <button
        mat-raised-button
        color="accent"
        type="button"
        class="text-white"
        (click)="toggleCollapse()"
      >
        <mat-icon>{{ expanded() ? 'remove' : 'add' }}</mat-icon>
        {{ '更多條件' | translate }}
      </button>
      <button mat-raised-button color="primary" (click)="search()">
        <mat-icon>search</mat-icon>
        {{ '搜尋' | translate }}
      </button>
    </div>
  </div>
</form>

<ng-content></ng-content>
<!-- [onlyInput]="onlyInput()"
[onlyInput]="onlyInput()"
[onlyInput]="onlyInput()"
[onlyInput]="onlyInput()"
[onlyInput]="onlyInput()" -->
